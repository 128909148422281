import { Link as LinkBase } from '@components/links/Link';
import { styled } from 'styled-components';
import { BlueButton } from '@hn-ui/buttons';
import { ButtonLink as ButtonLinkBase } from '@components/links/ButtonLink';
import { BlueLink as BlueLinkBase } from '@hn-ui/links';

export const Title = styled.h2`
  font-size: 32px;
  font-weight: 700;
  line-height: 40px;
  letter-spacing: -0.6px;

  ${({ theme: { down } }) => down('tabletMd')} {
    font-size: 24px;
    line-height: 32px;
    letter-spacing: -0.2px;
  }

  ${({ theme: { down } }) => down('phone')} {
    font-size: 18px;
    line-height: 24px;
    letter-spacing: unset;
  }
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-flow: column;
  gap: 16px;
  width: 370px;
  align-items: center;

  ${({ theme: { down } }) => down('phone')} {
    width: 282px;
    gap: 16px;
  }
`;

export const Container = styled.div`
  display: flex;
  flex-flow: column;
  gap: 36px;
  align-items: center;
`;

export const Text = styled.p`
  font-size: 16px;
  line-height: 24px;
  text-align: center;

  ${({ theme: { down } }) => down('phone')} {
    font-size: 14px;
  }
`;

export const TitleContainer = styled.div`
  display: flex;
  flex-flow: column;
  gap: 16px;
  align-items: center;

  ${({ theme: { down } }) => down('tabletMd')} {
    gap: 8px;
  }
`;

export const ControlsContainer = styled.div`
  display: flex;
  flex-flow: column;
  align-items: center;
  gap: 24px;
  width: 370px;
  transform: translateY(-24px);

  ${({ theme: { down } }) => down('phone')} {
    width: 282px;
    gap: 16px;
  }
`;

export const Link = styled(LinkBase)`
  ${({ theme: { down } }) => down('phone')} {
    font-size: 14px;
  }
`;

export const Button = styled(BlueButton)`
  ${({ theme: { down } }) => down('phone')} {
    font-size: 14px;
    padding: 8px 20px;
  }
`;

export const ButtonLink = styled(ButtonLinkBase)`
  ${({ theme: { down } }) => down('phone')} {
    font-size: 14px;
    padding: 8px 20px;
  }
`;

export const BlueLink = styled(BlueLinkBase)`
  ${({ theme: { down } }) => down('phone')} {
    font-size: 14px;
  }
`;
